import preset from "@rebass/preset"
import { injectGlobal } from "emotion"

injectGlobal`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

  @font-face {
    font-family: 'PressStart';
    src: url(https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/press_start_2p.ttf);
    font-style: normal;
  }

  @font-face {
    font-family: 'BreakBeatBtnOutline';
    src: url(https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/breakbeat_btn_outline.ttf);
    font-style: normal;
  }
`

const theme = {
  ...preset,

  breakpoints: ["768px", "1024px", "1200px"],

  colors: {
    ...preset.colors,
    text: "#1a1818",
    primary: "#a18fff",
    primarylight: "#92a5fd",
    secondary: "#00205b",
    white: "#fff",
    dark: "#1a1818",
    gray: "#7e7e7e",
    lightgray: "#dedede",
    pink: "#ff8692",
    lightgreen: "#33ffcc",
    almostwhite: "#e5eaf5",
    watergreen: "#0bffc9",
    orange: "#fd6f3b",
  },

  fonts: {
    ...preset.fonts,
    body: "'Montserrat', system-ui, sans-serif",
  },

  fontSizes: [14, 18, 26, 32, 48, 96],

  space: [0, 4, 8, 12, 18, 24, 32, 48, 64, 96, 128, 256, 512],

  fontWeights: {
    light: 300,
    body: 400,
    heading: 500,
    bold: 700,
  },

  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },

  radii: {
    default: 8,
    circle: 99999,
  },

  text: {
    mainHeading: {
      px: [5, 9],
      zIndex: 1,
      textAlign: "center",
      fontWeight: 600,
      fontSize: [2, 3, 4],
      "> span": {
        position: "relative",
        display: "inline-block",
      },
      "> span:after": {
        content: "''",
        width: "100%",
        position: "absolute",
        display: "block",
        zIndex: -1,
        bottom: "5px",
        height: "12px",
        backgroundColor: "#33ffcc",
      },
    },
  },
}

export default theme
