// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-assets-index-js": () => import("./../../../src/templates/assets/index.js" /* webpackChunkName: "component---src-templates-assets-index-js" */),
  "component---src-templates-careers-marketing-index-js": () => import("./../../../src/templates/careers/marketing/index.js" /* webpackChunkName: "component---src-templates-careers-marketing-index-js" */),
  "component---src-templates-contact-us-index-js": () => import("./../../../src/templates/contact-us/index.js" /* webpackChunkName: "component---src-templates-contact-us-index-js" */),
  "component---src-templates-cookie-index-js": () => import("./../../../src/templates/cookie/index.js" /* webpackChunkName: "component---src-templates-cookie-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-manifesto-index-js": () => import("./../../../src/templates/manifesto/index.js" /* webpackChunkName: "component---src-templates-manifesto-index-js" */),
  "component---src-templates-press-kit-index-js": () => import("./../../../src/templates/press-kit/index.js" /* webpackChunkName: "component---src-templates-press-kit-index-js" */),
  "component---src-templates-privacy-for-u-index-js": () => import("./../../../src/templates/privacy-for-u/index.js" /* webpackChunkName: "component---src-templates-privacy-for-u-index-js" */),
  "component---src-templates-privacy-index-js": () => import("./../../../src/templates/privacy/index.js" /* webpackChunkName: "component---src-templates-privacy-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-team-index-js": () => import("./../../../src/templates/team/index.js" /* webpackChunkName: "component---src-templates-team-index-js" */)
}

